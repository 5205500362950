import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Image from "gatsby-plugin-sanity-image";
import cx from "classnames";
import { Link } from "gatsby";

import { QueryFragments } from "../GraphQl/queryFragments"; // eslint-disable-line
import { PageLoader } from "../components/pageLoader";
import YellowArrow from "../svg/yellow-arrow.svg";

const TeamMember = ({ data: { teamMember } }) => {
    const { name, slug, bio, phone, role, email, image, teamSays } =
        teamMember || {};

    //extract first name from name
    const firstName = name.split(" ")[0];

    return (
        <>
            <div className="bg-teal-01  relative text-warm-white">
                <h1 className="blockH1 text-center py-[150px] lg:py-[200px]">
                    {name}
                </h1>
                <div className="grid grid-cols-14 pb-16 ">
                    <div className="col-start-2 col-end-14 lg:col-end-6 lg:sticky lg:top-0 lg:self-start pt-10 -mt-10 pb-2">
                        <h3 className="blockH5 font-bold">{role}</h3>
                        <div className="pt-7 blockH9">
                            <a className="block" href={`mailto:${email}`}>
                                <span className="font-bold text-warm-white inline-block pr-2">
                                    E
                                </span>
                                {email}
                            </a>
                            <a className="block" href={`tel:${phone}`}>
                                <span className="font-bold text-warm-white inline-block pr-2">
                                    P
                                </span>
                                {phone}
                            </a>
                        </div>
                        <Link className="flex mt-10" to="/about">
                            <YellowArrow className="w-[9px] h-auto rotate-180 mr-3" />
                            <p className="blockH8 font-bold">Back to team</p>
                        </Link>
                    </div>
                    <div className="col-start-2 lg:col-start-7 col-end-14  mt-10 lg:mt-0 relative aspect-w-7 aspect-h-8 overflow-hidden">
                        <Image
                            className="absolute top-0 left-0 object-cover object-top"
                            {...image}
                        />
                    </div>
                    <div className="col-start-2 lg:col-start-7 col-end-14 pt-8">
                        <p className="blockH7 text-warm-white ">{bio}</p>
                    </div>
                    <div className="col-start-2 lg:col-start-7 col-end-14 pt-8">
                        <h4 className="blockH7">{`What the team says about ${firstName}:`}</h4>
                        <p className="text-warm-white pt-2">{teamSays}</p>
                    </div>
                </div>
            </div>
            <PageLoader />
        </>
    );
};

export default TeamMember;

export const pageQuery = graphql`
    query standardteamMemberQuery($slug: String!) {
        teamMember: sanityTeamMember(slug: { current: { eq: $slug } }) {
            name
            slug {
                current
            }
            bio
            teamSays
            phone
            role
            email
            image {
                ...Image
            }
        }
    }
`;
